<template>
    <DsCard @click="$router.push(link)">
        <div class="thumbnail-container">
            <img v-if="hackathon.thumbnail || hackathon.image" class="img" :src="hackathon.thumbnail || hackathon.image" />
            <img v-else-if="fillerImageIndex === 1" class="filler-img" src="@/assets/images/hacker.png" />
            <img v-else-if="fillerImageIndex === 2" class="filler-img" src="@/assets/images/binary.png" />
            <img v-else-if="fillerImageIndex === 3" class="filler-img" src="@/assets/images/placeholder-box.png" />
            <img v-else class="filler-img" src="@/assets/images/stick-figure.png" />
        </div>

        <DsCardFooter class="footer">
            <h4 class="title">
                {{ hackathon.name }}
            </h4>

            <small v-if="hackathon.disablePresenting" class="not-presenting">(Not presenting)</small>

            <div class="members">
                <DsAvatar
                    v-if="hackathon.teamLead"
                    class="team-lead"
                    :title="getUser(hackathon.teamLead).name"
                    :size="26"
                    :image-url="getUser(hackathon.teamLead).photoUrl"
                />

                <DsAvatar
                    v-for="member in hackathon.members"
                    :key="member.id"
                    :title="getUser(member).name"
                    :size="24"
                    :image-url="getUser(member).photoUrl"
                />
            </div>

            <div v-if="winner" class="winner">
                <DsIcon name="award" />
                <span class="name">{{ winner }}</span>
            </div>

            <div v-if="showRoles && hackathon.rolesWanted.length" class="members-wanted">
                Wanted:
                <DsChip
                    v-for="role in hackathon.rolesWanted"
                    :key="role"
                >
                    {{ roles[role] }}
                </DsChip>
            </div>
        </DsCardFooter>
    </DsCard>
</template>

<script>
import { mapGetters } from 'vuex';
import { roles } from '@/components/project/constants';

export default {
    props: {
        link: String,
        linkText: String,
        winner: String,
        fillerImageIndex: {
            type: Number,
            default: 0,
        },
        showRoles: Boolean,
        hackathon: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            roles,
        };
    },

    computed: {
        members() {
            const { teamLead, members } = this.hackathon;

            return [teamLead, ...members];
        },

        ...mapGetters({
            getUser: 'users/getUser',
        }),
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .members {
      display: flex;
      padding: $spacing-200 0 $spacing-050;
      justify-content: center;
    }

    .members-wanted {
      display: flex;
      align-items: center;
      padding: $spacing-050 0 $spacing-200;
    }

    .title {
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .img, .filler-img {
        background-color: $color-gray-lighter;
        max-width: 100%;
        max-height: 250px;
    }

    .thumbnail-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $spacing-050 0 $spacing-100;
    }

    .winner {
        @include ellipsis;
        width: 100%;
        display: flex;
        margin: $spacing-100 0;
        color: $color-green;
        align-items: center;
        justify-content: center;
    }

    .avatar {
        @include margin-start(2px);

        &.team-lead {
            border: 1px solid $color-green;
        }
    }

    .not-presenting {
        color: $color-red-400;
    }
</style>
