<template>
    <div>
        <portal to="title">
            Tags
        </portal>

        <portal to="nav-actions">
            <DsFilledButton gray @click="$router.push({ name: 'tags.new' })">
                Add tag
            </DsFilledButton>
        </portal>

        <DsInputField
            v-model="searchText"
            placeholder="Filter tags"
            class="search-box"
        />

        <div v-if="noResults" class="no-results">
            <DsInlineAlert>
                No results found

                <template #footer>
                    <DsFilledButton
                        leading-icon
                        type="subtle"
                        gray
                        @click="searchText = ''"
                    >
                        Clear filter
                    </DsFilledButton>
                </template>
            </DsInlineAlert>
        </div>

        <DsListItem
            v-for="tag in filteredTags"
            :key="tag.id"
            border-style="full"
            clickable
            @click="$router.push({ name: 'tags.edit', params: { id: tag.id } })"
        >
            <template #title>
                {{ tag.name }}
            </template>
        </DsListItem>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            searchText: '',
        };
    },

    computed: {
        noResults() {
            return this.searchText?.trim()?.length > 0 && this.filteredTags?.length === 0;
        },

        filteredTags() {
            return this.searchText
                ? this.tags.filter((tag) => tag.name.toLowerCase().trim().includes(this.searchText.toLowerCase().trim()))
                : this.tags;
        },

        ...mapState({
            tags: ({ tags }) => tags.tags,
        }),
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .search-box {
        margin: $spacing-200;
        max-width: 300px;
    }
</style>
