import { render, staticRenderFns } from "./EditTagPage.vue?vue&type=template&id=08775730&scoped=true&"
import script from "./EditTagPage.vue?vue&type=script&lang=js&"
export * from "./EditTagPage.vue?vue&type=script&lang=js&"
import style0 from "./EditTagPage.vue?vue&type=style&index=0&id=08775730&prod&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08775730",
  null
  
)

export default component.exports