<template>
    <div>
        <div class="guest-invite-form">
            <h3>Invite a guest</h3>

            <form
                class="add-guest-form"
                @submit.prevent="addGuest"
            >
                <DsInputField
                    v-model="email"
                    placeholder="Guest email"
                    type="email"
                    required
                    style="--input-margin-bottom: 0"
                />

                <DsDatePicker
                    v-model="accessEndDate"
                    name="basic"
                    label="Grant access until"
                />

                <DsFilledButton
                    type="submit"
                    :loading="saving"
                    :disabled="isGuestAlreadyInvited"
                >
                    Add guest
                </DsFilledButton>
            </form>
        </div>

        <DsInlineAlert
            v-if="isGuestAlreadyInvited"
            class="already-invited-alert"
            type="warning"
            leading-icon
        >
            Guest email already in use.
        </DsInlineAlert>

        <DsListItem
            v-for="guest in guests"
            :key="guest.id"
            border-style="full"
        >
            <template #leading>
                <DsAvatar
                    :size="40"
                    :email="guest.email"
                />
            </template>

            <template #title>
                {{ guest.email }}
                Invited by <router-link :to="{ name: 'user', params: { id: guest.invitedBy } }">
                    {{ getUser(guest.invitedBy).name }}
                </router-link>
            </template>

            <template #trailing>
                <DsFilledButton
                    destructive
                    name="trash"
                    :loading="guest.id === deleting"
                    @click="deleteGuest(guest.id)"
                >
                    Remove guest
                </DsFilledButton>
            </template>
        </DsListItem>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';

export default {
    data() {
        return {
            email: '',
            accessEndDate: '',
            deleting: null,
            saving: false,
        };
    },

    mounted() {
        this.loadGuests();
    },

    computed: {
        ...mapState({
            guests: ({ users }) => users.guests,
            userId: ({ auth }) => auth.user.uid,
        }),

        ...mapGetters({
            activeHackathon: 'hackathons/getActiveHackathon',
            guestsList: 'users/guestsList',
            getUser: 'users/getUser',
        }),

        isGuestAlreadyInvited() {
            return this.guestsList?.includes(this.email);
        },
    },

    methods: {
        async loadGuests() {
            await this.$store.dispatch('users/LOAD_GUEST_LIST');

            this.accessEndDate = this.activeHackathon?.endDate || '';
        },

        async addGuest() {
            try {
                this.saving = true;
                const { email, accessEndDate } = this;

                const payload = {
                    email,
                    accessEndDate: moment(accessEndDate).format(),
                    accessStartDate: moment().format(),
                    invitedBy: this.userId,
                };

                await this.$store.dispatch('users/ADD_GUEST_USER', payload);
                this.accessEndDate = this.activeHackathon?.endDate || '';
                this.email = '';
            } catch (e) {
                //
            }

            this.saving = false;
        },

        async deleteGuest(guestId) {
            try {
                this.deleting = guestId;
                await this.$store.dispatch('users/DELETE_GUEST_USER', guestId);
                await this.$store.dispatch('users/LOAD_GUEST_LIST');
            } catch (e) {
                //
            }

            this.deleting = null;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .no-results {
        display: flex;
        align-items: center;
        margin: 0 $spacing-200 $spacing-200;
    }

    .guest-invite-form {
        background: $color-gray-100;
        padding: $spacing-200;
        border-radius: $border-radius;
    }

    form {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        align-items: center;
        padding-top: $spacing-200;
        grid-gap: $spacing-200;

        --input-margin-bottom: 0;
    }

    .already-invited-alert {
        margin-top: $spacing-200;
    }
</style>
